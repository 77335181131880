import firebase from 'firebase/app';
import { Investor, BankAccountChange, User, DataChangeRequest } from './user';
import { Payment, Investment, InvestmentInvoice, PaymentStatus } from './investment';
import { Asset, FundingTypes, InterestRateTypes, InvoicePaymentStatus } from './asset';
import { IdentificationRequest } from './identificationRequest';
import { IdinIssuers, Idin } from './idin';
import { PaymentMethod } from './checkout';
import { AssetPaymentMethod, CreateMerchantSettlementInterval } from './opp';
import { SetupFee } from './fee';

/**
 * Generic Bloqify settings.
 */
export interface Settings {
  version: string;
  updatedDateTime: string;
}

// Client Docs
export interface ClientDocs {
  logo: string[],
  registration: string[],
}

// Client contact information
export interface ClientData {
  name: string,
  companyName: string,
  email: string,
  url: string,
  dir: string,
  telephone: string,
  supportName: string,
  kvk?: string,
  iban?: string,
  bic?: string,
  btw?: string,
}

export enum AlignmentOptions {
  left = 'left',
  center = 'center',
  right = 'right',
}

export enum SizeOptions {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

export enum FontOptions {
  Default = 'Default',
  Brother1816 = 'Brother1816',
  DMsans = 'DMsans',
  Arial = 'Arial',
}

export enum StyleOptions {
  regular = 'Regular',
  italic = 'Italic',
  bold = 'Bold',
  boldItalic= 'BoldItalic',
}

// Doc Format
export interface DocFormat {
  logoAlignment: AlignmentOptions,
  logoSize: SizeOptions,
  titleAlignment: AlignmentOptions,
  titleSize: SizeOptions,
  titleStyle: StyleOptions,
  fontSize: SizeOptions,
  font: FontOptions,
}

export enum ObjectContentOptions {
  Address = 'address',
  ProjectName = 'projectname',
}

// Dynamic changes in the invoice pdf document
interface InvoiceFormat {
  showInvestors: boolean, // Show list of investors in the invoice
  showAllocation: boolean, // Show payment split with investors names, amounts and bankaccounts
  showLoanTakerInfo: boolean, // Show loantaker address in Invoice
  showPeriodNumber: boolean, // Displays period number instead of period dates
  onlyUseFirstLoantaker: boolean, // Only send the name of the first loantaker
  paymentDayInvoices: number, // Day of the month to pay the invoices
  paymentMonthInvoices: number, // Month to pay the invoices (0 - current month, 1 - next month)
  objectContent: ObjectContentOptions, // Content to display in the invoices in the object fields
  invoiceFileName: string, // Name of the file used to store the rente notas
  footerText: { [key in AssetPaymentMethod]: string }, // Additional text to the footer of the invoice nota
}

interface InvoiceFormula {
  dateFormat: number, // 0 - Precise / 1 - 30-360
  interestFormat: number, // 0 - Count the day changed / 1 - Start counting next day
  repaymentsFormat: number, // 0 - Count the day changed / 1 - Start counting next day
  endFormat: number, // 0 - Count the day changed / 1 - Start counting next day
}

// Config for the reminders
export interface Reminder {
  enabled: boolean,
  template: string,
  to: 'loanTakers' | 'support' | 'investor',
  daysDiff: number,
}

// Config for the asset reminders
export interface AssetReminder extends Reminder {
  field: 'startDateTime' | 'endDateTime',
  to: 'loanTakers' | 'support',
  // TODO in auto invest
  status: 'published' |'activated' | 'finished',
}

// Config for the invoice reminders
export interface InvoiceReminder extends Reminder {
  field: 'sendDateTime' | 'period.start' | 'period.end',
  to: 'loanTakers' | 'support' | 'investor',
  status: InvoicePaymentStatus,
}

// Config for the payment reminders
export interface PaymentReminder extends Reminder {
  field: 'createdDateTime' | 'paymentDateTime' | 'requestedDateTime' | 'refundedDateTime',
  to: 'investor' | 'support',
  status: PaymentStatus,
}

// Sendgrid templates
interface Templates {
  acceptOperation: string,
  passwordReset: string,
  verifyEmail: string,
  sendMandateActivation: string,
  mandateRefunded: string,
  mandateRevoked: string,
  identificationRequestSuccess: string,
  identificationRequestFailed: string,
  sharesBought: string,
  sharesReserved: string,
  sendInvoice: string,
  sendInvoiceDirectLink: string,
  sendInvoiceMandate: string,
  sendInvoiceDeposit: string,
  sendManualInvoiceDirectLink: string,
  sendRepaymentDirectLink: string,
  investorInvoice: string,
  invoicePaidNotification: string,
  notifyPublishedAsset: string,
  requestPayment: string,
  assetReminders: AssetReminder[],
  invoiceReminders: InvoiceReminder[],
  paymentReminders: PaymentReminder[],
}

export enum AssetVisibility {
  Invested = 'invested',
  NotFull = 'not-full',
}

export enum ClientCountry {
  NL = 'NL',
  BE = 'BE',
  ES = 'ES',
  US = 'US',
  UK = 'UK',
}

export interface TextLangOptions {
  en: string;
  nl: string;
}

export enum UserSelectors {
  PrivateName = 'PrivateName',
  CompanyName = 'CompanyName',
  Both = 'Both',
}

export interface AdminPortalConfig {
  showExternalId: boolean;
  userSelectors: UserSelectors;
}

export interface InvestorPortalConfig {
  showAssets: boolean;
  assetVisibility: AssetVisibility[];
  showWithdrawDisclaimer: boolean;
  showDownloadInvoice: boolean;
  showProgressBar: boolean;
  showInterestRate: boolean;
  showPersonalDoc: boolean;
  requireQuestionnaire: boolean;
  requireContractAgreement: boolean;
  requirePrivateApproval: boolean;
  requireBusinessApproval: boolean;
  showPhoneNumberInput: boolean;
  clientCountry: ClientCountry;
  requireBankAccount: boolean,
  requireIdCopy: boolean,
  requireTelephone: boolean,
  formalTranslations: boolean,
  showInvoiceManagementFee: boolean,
  showInvoiceManagementFeeVat: boolean,
  showManagementFee: boolean,
  investmentConditions: TextLangOptions,
  registrationConditions: TextLangOptions,
  decimals: number,
  questionnaireValidity: number,
  simulationTestValidity: number,
}

/**
 * Admin settings. Shuts down the application.
 * Holds others configs of the app
 */
export interface Admin {
  maintenance: boolean; // ShutDown BQ app
  bloqadminMaintenace: boolean; // ShutDown BA app
  slowPayerProtection: boolean, // ShutDown BA app because the client hasn't paid last invoices
  userEmailing: boolean; // Enable emailing for user actions (identificationRequest; createUser; passwords...)
  businessEmailing: boolean; // Enable emailing for business actions (sharesBought; sendInvoices; reminders...)
  sendInvoices: number; // Day of the month to send the invoices; should be greater than generateInvoice
  generateInvoices: number; // Day of the month to generate the invoices
  oppIntegration: boolean; // Enable opp features in the project
  defaultPaymentMethod: AssetPaymentMethod; // Default payment method of the assets
  defaultInterestRateType: InterestRateTypes; // Default interest rate type of the assets
  paymentFees: boolean; // Enable payment fees
  clientData: ClientData; // Whitelabel info
  clientDocs: ClientDocs; // Platform documents
  docFormat: DocFormat; // Format for the pdf of the platform
  templates: Templates; // Sengrid templates
  invoiceFormat: InvoiceFormat; // Changes in the invoice doc generated
  invoiceFormula: InvoiceFormula; // Settings available to apply in the invoice generation formula
  availableLanguages: string[]; // Available languages in the app
  pescheck: boolean; // enable or disable pescheck
  investorPortalEnabled: boolean; // enable or disable login and register from bq
  clientAccount?: firebase.firestore.DocumentReference; // Client app account
  paymentOptions: PaymentMethod[]; // Allowed payment options
  defaultProjectFunding: FundingTypes; // Default project funding
  support_email: string; // Support Email, previously on the firebase functions config
  base_url: string; // Platform url, previously on the firebase functions config
  investorPortalConfig: InvestorPortalConfig; // Different setups for the bloqify page
  adminPortalConfig: AdminPortalConfig;
  sendInvoicePaidNotification: boolean; // Send an email to investors when an invoice is paid
  countryVat: number; // Vat of the country where the platform mainly operate
  managementFeeFormula: SetupFee; // Formula to apply in the management fee
  administrationFeeFormula: SetupFee; // Formula to apply in the administration fee
  defaultAssetSetupFees: SetupFee[]; // Default fees when creating a new asset
  requireBankAccountMatch: boolean; // Enforce that investors bankaccount is the same in our bd and in Opp
  walletSettlement: CreateMerchantSettlementInterval; // Investors wallet settlement config
  allowWalletPayment: boolean; // Allows investing with investor wallet funds
  overidePayoutProtection: boolean; // Allow to perform payouts without having to wait 4 days
}

/**
 * Main basic unit in the state.
 */
 export interface StateSlice<T = any> {
  status: string;
  payload?: T | any;
  error?: string;
  name?: string;
}

// -- DOWNLOADS -- //
/**
 * State interface for the withDownloads wrapper.
 */

/**
 * State structure for an asset's files.
 */
 export interface AssetDownloads {
  images?: StateSlice<[string, string][]>;
  floorPlanImages?: StateSlice<[string, string][]>;
  prospectus?: StateSlice<[string, string][]>;
  brochure?: StateSlice<[string, string][]>;
}

/**
 * State structure for the user's files.
 */
export interface UserDownloads {
  avatar?: StateSlice<[string, string][]>;
}
 export interface Downloads {
  assets?: {
    [key: string]: AssetDownloads;
  };
  investor?: UserDownloads;
}

/**
 * Vuex store's State (tree).
 */

// -- AUTH -- //

// enum with all pages of the Auth, thus string = path
export enum AuthComponents {
  Login = 'login',
  AuthVerification = 'auth-verification',
  Reset = 'reset',
  Register = 'register',
}
export interface AuthModal {
  isOpen: boolean,
  type: AuthComponents | null,
  routeTo?: string | null,
}
export interface State {
  auth: { [key: string]: any } | null;
  user: User | Investor | null;
  selectedInvestor: User | Investor | null;
  assets: Asset[];
  idin: Idin | null;
  investments: Investment[],
  payment: StateSlice;
  payments: Payment[];
  invoices: InvestmentInvoice[];
  support: { [key: string]: any } | null;
  manualOrder: { [key: string]: any } | null;
  operations: StateSlice;
  idinIssuers: IdinIssuers | null;
  identificationRequest: IdentificationRequest | null;
  dataChangeRequests: DataChangeRequest[] | null;
  bankAccountChanges: BankAccountChange[];
  downloads: Downloads | null;
  settings: Settings | null;
  admin: Admin | null;
  authModal: AuthModal;
  misc: { initialTooltip: boolean }
  [key: string]: { [key: string]: any } | null | any[];
  // customLogin: { comesFromMobile?: boolean };
}

/**
 * Implementing StateSlice.
 */
export class InitialStateSlice implements StateSlice {
  status = '';
  payload = null;
  error = '';
  name = '';
}

/**
 * Function that generates the store avoiding (or not) the modules.
 * @param includeModules handles the inclusion of the modules in the freshly generated state.
 */
export const generateInitialRootState = (includeModules?: boolean): { [key: string]: any } => ({
  auth: null,
  user: null,
  selectedInvestor: null,
  support: null,
  manualOrder: null,
  operations: new InitialStateSlice(),
  idinIssuers: null,
  identificationRequest: null,
  dataChangeRequests: null,
  bankAccountChanges: [],
  settings: null,
  admin: null,
  misc: { initialTooltip: false },

  // Including all the modules
  ...includeModules && {
    assets: [],
    investments: [],
    payments: [],
    invoices: [],
    idin: null,
    payment: new InitialStateSlice(),
    downloads: {},
  },
});
