export const enum PaymentMethod {
  wallet = 'wallet',
  ideal = 'ideal',
  bcmc = 'bcmc',
  sepa = 'sepa',
  creditcard = 'creditcard',
  'sofort-banking' = 'sofort-banking',
  mybank = 'mybank',
  giropay = 'giropay',
}

export enum IdentificationCheckoutStepNames {
  Identification = 'identification', // normal identification component
  Questionnaire = 'questionnaire ', // Questionnaire
  RiskQuestionnaire = 'riskQuestionnaire',
}

export enum CheckoutStepNames {
  Questionnaire = 'questionnaire ', // Questionnaire
  Identification = 'identification', // normal identification component
  RiskQuestionnaire = 'riskQuestionnaire',
  Investment = 'amount', // CheckoutInvestment
  Terms = 'terms', // CheckoutLegal
  PaymentMethods = 'payment-methods', // CheckoutPaymentMethods
  Payment = 'payment', // no component
  Status = 'status', // CheckoutStatus
}

export type PaymentOptions = {
  id: number,
  image: string,
  value: PaymentMethod
}

export type Checkout = {
  assetId: string,
  sharesAmount: number,
  eurAmount: number,
  euroAmount: number,
  totalEur: number,
  totalEmissionCosts: number,
  selectedDividendsFormatYear: [string, number],
  nextStep?: CheckoutStepNames | IdentificationCheckoutStepNames | 'lastStep',
  questionaireQuestionAndAnswers: { question: string, answer: string, type: string }[],
  experienceQuestionAndAnswers: { question: string, answer: string, type: string }[],
  experienceQuestionAndAnswersUnprocessed: { question: string, answer: string, type: string }[],
  investmentTargetsQuestionAndAnswers: { question: string, answer: string, type: string }[],
  investmentTargetsQuestionAndAnswersUnprocessed: { question: string, answer: string, type: string }[],
  financialSituationQuestionAndAnswers: { question: string, answer: string, type: string }[],
  financialSituationQuestionAndAnswersUnprocessed: { question: string, answer: string, type: string }[],
  platformSpecificQuestionAndAnswers: { question: string, answer: string, type: string }[],
  platformSpecificQuestionAndAnswersUnprocessed: { question: string, answer: string, type: string }[],
  simulationCalcuationResult: number,
  netAnnualIncome: number,
  liquidAssetsValue: number,
  financialCommitmentsValue: number,
  incomeType: null | boolean,
  agressWithRisk: null | boolean,
  agressWithRiskWarning: null | boolean,
  paymentMethod: null | PaymentMethod,
};

export interface CheckoutCloudFunctionParameters {
  assetId: string,
  redirectUrl: string,
  sharesAmount: number,
  lang: string,
  selectedDividendsFormatYear: [string, number],
  brand?: string
  type: undefined | 'loan', // this is not a parameter in the CF but we still send it for now
  paymentMethod?: PaymentMethod, // If it's not a reservation asset
  payFromWallet?: number,
}

export type Step = {
  name: CheckoutStepNames | IdentificationCheckoutStepNames,
  /**
   * what is `matched` in the router
   */
  route: string,
  /**
   * the pointer to the correct v18n property
   */
  display: string,
};
