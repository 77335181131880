import { CheckoutStepNames, Step } from '@/store/models/checkout';
// eslint-disable-next-line import/extensions,import/no-unresolved
import { modules } from '../../whitelabel.config';

export enum IdentCheckoutRouteNames {
  MAIN = 'checkoutIdentificationMain',
  IDIN = 'checkoutIdentificationIdin',
  WORLD = 'checkoutIdentificationWorld',
  BUSINESS = 'checkoutIdentificationBusiness',
  MAINWITHOUTINVESTMENT = 'checkoutIdentificationMainWI',
  IDINWITHOUTINVESTMENT = 'checkoutIdentificationIdinWI',
  WORLDWITHOUTINVESTMENT = 'checkoutIdentificationWorldWI',
  BUSINESSWITHOUTINVESTMENT = 'checkoutIdentificationBusinessWI',
}

export const IdentifcationPath = 'identification';
/*
* Steps in the checkout flow
* The order is the same as it is displayed in
* the identification and contract agreement are depended on the config in whitelabel.config
* */
// eslint-disable-next-line import/no-mutable-exports
export let paymentSteps: Step[] = [
  ...modules.identification
  ? [
    {
      name: CheckoutStepNames.Identification,
      route: `/:lang?/checkout/${IdentifcationPath}/`,
      display: 'settings.identification.identification',
    },
  ] : [],
  {
    name: CheckoutStepNames.Questionnaire,
    route: '/:lang?/checkout/questionnaire/',
    display: 'checkout.questionnaire.questionnaire',
  },
  {
    name: CheckoutStepNames.RiskQuestionnaire,
    route: '/:lang?/checkout/riskquestionnaire/',
    display: 'checkout.riskquestionnaire.riskquestionnaire',
  },
  {
    name: CheckoutStepNames.Investment,
    route: '/:lang?/checkout/investment/',
    display: 'checkout.fund.selectAnAmount',
  },
  {
    name: CheckoutStepNames.Terms,
    route: '/:lang?/checkout/legal/',
    display: 'checkout.legal.legalAgreement',
  },
  {
    name: CheckoutStepNames.PaymentMethods,
    route: '/:lang?/checkout/payment-methods/',
    display: 'checkout.payment.paymentMethods',
  },
  {
    name: CheckoutStepNames.Payment,
    route: '/:lang?/checkout/payment/',
    display: 'common.payment',
  },
];

export const reloadPaymentSteps = (requireQuestionnaire: boolean, requireContractAgreement: boolean): void => {
  paymentSteps = [
    ...modules.identification
    ? [
        {
          name: CheckoutStepNames.Identification,
          route: `/:lang?/checkout/${IdentifcationPath}/`,
          display: 'settings.identification.identification',
        },
      ]
    : [],
    ...requireQuestionnaire
    ? [
        {
          name: CheckoutStepNames.Questionnaire,
          route: '/:lang?/checkout/questionnaire/',
          display: 'checkout.questionnaire.questionnaire',
        },
        {
          name: CheckoutStepNames.RiskQuestionnaire,
          route: '/:lang?/checkout/riskquestionnaire/',
          display: 'checkout.riskquestionnaire.riskquestionnaire',
        },
    ] : [],
    {
      name: CheckoutStepNames.Investment,
      route: '/:lang?/checkout/investment/',
      display: 'checkout.fund.selectAnAmount',
    },
    ...requireContractAgreement
    ? [
        {
          name: CheckoutStepNames.Terms,
          route: '/:lang?/checkout/legal/',
          display: 'checkout.legal.legalAgreement',
        },
      ]
    : [],
    {
      name: CheckoutStepNames.PaymentMethods,
      route: '/:lang?/checkout/payment-methods/',
      display: 'checkout.payment.paymentMethods',
    },
    {
      name: CheckoutStepNames.Payment,
      route: '/:lang?/checkout/payment/',
      display: 'common.payment',
    },
  ];
};
