import { IdentificationCheckoutStepNames, Step } from '@/store/models/checkout';
// eslint-disable-next-line import/extensions,import/no-unresolved
import { modules, requireContractAgreement, requireQuestionnaire } from '../../whitelabel.config';

export enum IdentCheckoutRouteNames {
  MAIN = 'checkoutIdentificationMain',
  IDIN = 'checkoutIdentificationIdin',
  WORLD = 'checkoutIdentificationWorld',
  BUSINESS = 'checkoutIdentificationBusiness',
}

/*
* Steps in the checkout flow
* The order is the same as it is displayed in
* the identification and contract agreement are depended on the config in whitelabel.config
* */
// eslint-disable-next-line import/no-mutable-exports
export let idenSteps: Step[] = [
  ...modules.identification
  ? [
    {
      name: IdentificationCheckoutStepNames.Identification,
      route: '/:lang?/identification-checkout/identification/',
      display: 'settings.identification.identification',
    },
  ]
  : [],
  {
    name: IdentificationCheckoutStepNames.Questionnaire,
    route: '/:lang?/identification-checkout/questionnaire/',
    display: 'checkout.questionnaire.questionnaire',
  },
  {
    name: IdentificationCheckoutStepNames.RiskQuestionnaire,
    route: '/:lang?/identification-checkout/riskquestionnaire/',
    display: 'checkout.riskquestionnaire.riskquestionnaire',
  },
];

export const reloadIdenSteps = (requireQuestionnaire: boolean): void => {
  idenSteps = [
    ...modules.identification
    ? [
        {
          name: IdentificationCheckoutStepNames.Identification,
          route: '/:lang?/identification-checkout/identification/',
          display: 'settings.identification.identification',
        },
    ] : [],
    ...requireQuestionnaire
    ? [
        {
          name: IdentificationCheckoutStepNames.Questionnaire,
          route: '/:lang?/identification-checkout/questionnaire/',
          display: 'checkout.questionnaire.questionnaire',
        },
        {
          name: IdentificationCheckoutStepNames.RiskQuestionnaire,
          route: '/:lang?/identification-checkout/riskquestionnaire/',
          display: 'checkout.riskquestionnaire.riskquestionnaire',
        },
    ] : [],
  ];
};
