import { GetterTree } from 'vuex';
import { ComplianceStatus, MerchantStatus, UserTier } from '@/store/models/user';
import { ClientCountry, State } from './models';

export default <GetterTree<State, State>> {
  isUserLoggedIn: (state): boolean => state.auth?.uid,

  isOppEnabled: (state): boolean => !!state.admin?.oppIntegration,

  /**
   * Typeguard getter to check if the user is an investor.
   */
  isInvestor: ({ user }): boolean => user?.tier === UserTier.Investor,

  /**
   * Typeguard getter to check if the user is an investor.
   */
  isUser: ({ user }): boolean => user?.tier === UserTier.Account,

  hasInvalidMerchantOrCompliance: ({ user }): boolean =>
    !!(user?.oppData && (user?.oppData.merchantStatus !== MerchantStatus.live || user?.oppData.complianceStatus !== ComplianceStatus.verified)),

  hasWalletId: ({ user }, getter): boolean => !getter.isOppEnabled ? true : !!user?.walletId,

  walletIsSetUp: ({ user }, getter): boolean => !getter.isOppEnabled ? true : !!(user?.walletId && !getter.hasInvalidMerchantOrCompliance),

  walletOnReview: ({ user }, getter): boolean => !getter.isOppEnabled ? false : !!((user?.walletId && getter.hasInvalidMerchantOrCompliance)),

  canAnswerQuestionnaire: ({ user, admin }, getters): boolean => {
    if (user) {
      let canSubmitQuestionnaire = false;
      if (!user.questionnaireAnswered) {
        canSubmitQuestionnaire = true;
      } else if (user.questionnaireDateTime && user.limitedInvestments && admin?.investorPortalConfig.clientCountry === ClientCountry.BE) {
        const now = new Date().getTime();
        const questionnaireMillis = user.questionnaireDateTime.toMillis();
        // Define the threshold for 3 hours in milliseconds
        const threeHours = 3 * 60 * 60 * 1000;
        // Calculate the time difference in milliseconds
        const timeDifference = Math.abs(now - questionnaireMillis);

        if (timeDifference >= threeHours) {
          // can redo the questionnaire
          canSubmitQuestionnaire = true;
        }
      }

      return canSubmitQuestionnaire;
    }

    return false;
  },
};
